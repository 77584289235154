import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { HomeComponent } from '../home/home.component';
import { AppTopBarComponent } from '../topbar/app.topbar.component';
import { AppFooterComponent } from '../footer/app.footer.component';
import { RouterModule } from '@angular/router';
import { AppMenuComponent, AppSubMenuComponent } from '../menu/app.menu.component';
import { ScrollPanelModule } from 'primeng/primeng';

@NgModule({
  declarations: [DashboardComponent, HomeComponent, AppSubMenuComponent, AppMenuComponent, AppTopBarComponent, AppFooterComponent],
  imports: [
    ScrollPanelModule,
    CommonModule,
    RouterModule,
  ]
})
export class DashboardModule { }
