export class LeadVo{
    id: number;
    name: string;
    surname: string;
    nature: string; 
    recordTypeId: string; 
    campaign: string; 
    originId: string; 
    subOriginId: string; 
    subOriginN2Id: string; 
    clientType: string; 
    dateIntention: string; 
    dealerCode: string; 
    offerId: string; 
    nationalityId: string; 
    modelCode: string; 
    modelYear: number; 
    modelGroup: string; 
    productWanted: string; 
    version: string; 
    price: number; 
    descriptionOffer: string; 
    color: string; 
    finishing: string; 
    optional: string; 
    legalText: string; 
    cep: string; 
    cpfCnpj: string;  
    email: string; 
    ddd: number; 
    phoneNumber: string; 
    typePhone: string; 
    observations: string; 
    contactPreference: string; 
    authorizesData: boolean; 
    dateScheduling: Date; 
    plate: string; 
    totalVehicles: number; 
    chassis: string;
    createDateMask: string;
    companyName: string;
    statusSalesForce: string;
    dtSalesForceSent: string;
    descError: string;
    descSuccess: string;
    modelId: number;
    dealershipId: number;
    eventName: string;
    nameMask: string;
}