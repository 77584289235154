export enum DateType {
    DATE_TYPE_YYYYMMDDHHMMSS = 'YYYYMMDDHHMMSS',
    DATE_TYPE_DDMMYYYYHHMMSS = 'DDMMYYYYHHMMSS',
    DATE_TYPE_DD_MM_YYYY_1 = 'DD/MM/YYYY',
    DATE_TYPE_DD_MM_YYYY_2 = 'DD-MM-YYYY',
    DATE_TYPE_YYYY_MM_DD_1 = 'YYYY/MM/DD',
    DATE_TYPE_YYYY_MM_DD_2 = 'YYYY-MM-DD',
    DATE_TYPE_DD_MM_YYYY_HH_MM_SS_1 = 'DD/MM/YYYY HH:MM:SS',
    DATE_TYPE_DD_MM_YYYY_HH_MM_SS_2 = 'DD-MM-YYYY HH:MM:SS',
    DATE_TYPE_YYYY_MM_DD_HH_MM_SS_1 = 'YYYY/MM/DD HH:MM:SS',
    DATE_TYPE_YYYY_MM_DD_HH_MM_SS_2 = 'YYYY-MM-DD HH:MM:SS',
}
