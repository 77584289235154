// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // ####################### URL SERVIÇO LOCAL #######################
  // gatewayUrl: 'http://localhost:8090',
  // ####################### URL SERVIÇO HOMOLOGAÇÃO #######################
  gatewayUrl: 'https://leads-offline-back-hml.ageriservicos.com.br',
  // ####################### URL SERVIÇO PRODUÇÃO #######################
  version: '1.0.0.1',
  versionDate: '26/03/2019'
};
