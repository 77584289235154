import { Component, OnInit } from "@angular/core";
import { MessageService, SelectItem } from 'primeng/api';
import { LeadService } from "../services/lead.service";
import { Lead } from "../models/Lead";
import { BasicUtils } from "../utils/basic-utils";
import { DateType } from "../utils/date-type.enum";
import { LeadVo } from "../models/LeadVo";
import { Converters } from "../converters/Converters";
import { ValidatorCnpj } from "../validators/validator-cnpj";
import { Validar } from "../validators/validator";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: "app-list-lead",
    templateUrl: "./list-lead.component.html",
    styleUrls: ["./list-lead.component.css"],
    providers: [MessageService]
})
export class ListLeadComponent implements OnInit {
    selectedLeads: LeadVo[];
    cols: any[];
    leads: Lead[];
    email: string;
    cpfCnpj: string;
    startDate: Date;
    finishDate: Date;
    leadsVo: LeadVo[];
    documentsType: SelectItem[];
    documentType = 'cpf';

    constructor(
        private router: Router,
        private messageService: MessageService,
        private leadService: LeadService
    ) {}

    ngOnInit() {
        this.leads = new Array();
        this.selectedLeads = new Array();
        this. onCleanFilter();
        this.loadInitialChange();

        this.documentsType = [];
        // this.documentsType.push({label: 'Select City', value: 0});
        this.documentsType.push({label: 'CPF', value: 'cpf'});
        this.documentsType.push({label: 'CNPJ', value: 'cnpj'});
    }

    async loadInitialChange() {

      let startDate = BasicUtils.setTime(new Date(), 0, 0, 0);
      let finishDate = BasicUtils.setTime(new Date(), 23, 59, 59);

      await this.leadService
            .getLeadsByPeriod(startDate, finishDate)
            //.getAllLeads()
            .then(leads => (this.leads = leads))
            .catch((error) => {
              console.error('Erro ao recuperar quantidade de leads');
            });

      await this.setMaskToFields();

      this.cols = [
            { field: "id", header: "Id", class: "column-pattern-min"},
            { field: "email", header: "E-mail", class: "column-pattern"},
            { field: "cpfCnpj", header: "CPF ou CNPJ", class: "column-pattern"},
            { field: "nameMask", header: "Nome", class: "column-pattern-name"},
            // { field: "surname", header: "Sobrenome", class: "column-pattern-name"},
            
            { field: "companyName", header: "Concessionára", class: "column-pattern"},
            { field: "productWanted", header: "Produto Desejado", class: "column-pattern"},
            { field: "eventName", header: "Evento", class: "column-pattern"},
            { field: "createDateMask", header: "Criado em", class: "column-pattern"},
            { field: "statusSalesForce", header: "Enviado", class: "column-pattern"},
            { field: "dtSalesForceSent", header: "Enviado em", class: "column-pattern"},
            { field: "descSuccess", header: "Desc. Sucesso", class: "column-pattern-medium2"},
            

            { field: "descError", header: "Desc. Erro", class: "column-visible"},
            { field: "cep", header: "CEP", class: "column-no-visible"},
            { field: "ddd", header: "DDD", class: "column-no-visible"},
            { field: "phoneNumber", header: "Telefone", class: "column-no-visible"},
            { field: "typePhone", header: "Tipo de Telefone", class: "column-no-visible"},
            { field: "nature", header: "Natureza", class: "column-no-visible"},
            { field: "recordTypeId", header: "Record_Type_Id", class: "column-no-visible"},
            { field: "campaign", header: "Campanha", class: "column-no-visible"},
            { field: "originId", header: "Origem_Id", class: "column-no-visible"},
            { field: "subOriginId", header: "Sub_Origem_Id", class: "column-no-visible"},
            { field: "subOriginN2Id", header: "Sub_Origem_N2_Id", class: "column-no-visible"},
            { field: "clientType", header: "Tipo_Cliente", class: "column-no-visible"},
            { field: "dateIntention", header: "Data_Intencao", class: "column-no-visible"},
            { field: "dealerCode", header: "DN", class: "column-no-visible"},
            { field: "offerId", header: "Oferta_Id", class: "column-no-visible"},
            { field: "nationalityId", header: "Nacionalidade_Id", class: "column-no-visible"},
            { field: "modelCode", header: "Model_Code", class: "column-no-visible"},
            { field: "modelYear", header: "Model_Year", class: "column-no-visible"},
            { field: "modelGroup", header: "Model_Group", class: "column-no-visible"},

            { field: "version", header: "Versao", class: "column-no-visible"},
            { field: "price", header: "Preco", class: "column-no-visible"},
            { field: "descriptionOffer", header: "Desc_Oferta", class: "column-no-visible"},
            { field: "color", header: "Cor", class: "column-no-visible"},
            { field: "finishing", header: "Acabamento", class: "column-no-visible"},
            { field: "optional", header: "Opcionais", class: "column-no-visible"},
            { field: "legalText", header: "Texto_Juridico", class: "column-no-visible"},
            { field: "observations", header: "Observacao", class: "column-no-visible"},
            { field: "contactPreference", header: "Preferencia_Contato", class: "column-no-visible"},
            { field: "authorizesData", header: "Autoriza_Dados", class: "column-no-visible"},
            { field: "dateScheduling", header: "Data_Agendamento", class: "column-no-visible"},
            { field: "plate", header: "Placa", class: "column-no-visible"},
            { field: "totalVehicles", header: "Total_Veiculos", class: "column-no-visible"},
            { field: "chassis", header: "Chassi", class: "column-no-visible"},
        ];
    }

    async onSearch() {

      if (this.documentType === 'cnpj' && BasicUtils.isNotEmpty(this.cpfCnpj)) {
        if (!ValidatorCnpj.validatorCNPJ2(this.cpfCnpj)) {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Não é um CNPJ válido!' });
          return null;
        }
      }

      if (this.documentType === 'cpf' && BasicUtils.isNotEmpty(this.cpfCnpj)) {
        if (!Validar.validarCPF(this.cpfCnpj)) {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Não é um CPF válido!' });
          return null;
        }
      }

      if (BasicUtils.isNotEmpty(this.email) && BasicUtils.isEmpty(this.cpfCnpj) && (BasicUtils.isEmpty(this.startDate) && BasicUtils.isEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByEmail(this.email)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isEmpty(this.email) && BasicUtils.isNotEmpty(this.cpfCnpj) && (BasicUtils.isEmpty(this.startDate) && BasicUtils.isEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByCpfCnpj(this.cpfCnpj)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isEmpty(this.email) && BasicUtils.isEmpty(this.cpfCnpj) && (BasicUtils.isNotEmpty(this.startDate) && BasicUtils.isNotEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByPeriod(this.startDate, this.finishDate)
        .then(leads => (this.leads = leads))
        .catch((error: HttpErrorResponse) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Erro no processo!' });
        });
      } else if (BasicUtils.isNotEmpty(this.email) && BasicUtils.isNotEmpty(this.cpfCnpj) && (BasicUtils.isEmpty(this.startDate) && BasicUtils.isEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByEmailAndCpfCnpj(this.email, this.cpfCnpj)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isEmpty(this.email) && BasicUtils.isNotEmpty(this.cpfCnpj) && (BasicUtils.isNotEmpty(this.startDate) && BasicUtils.isNotEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByCpfCnpjAndPeriod(this.cpfCnpj, this.startDate, this.finishDate)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isNotEmpty(this.email) && BasicUtils.isEmpty(this.cpfCnpj) && (BasicUtils.isNotEmpty(this.startDate) && BasicUtils.isNotEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByEmailAndPeriod(this.email, this.startDate, this.finishDate)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isNotEmpty(this.email) && BasicUtils.isNotEmpty(this.cpfCnpj) && (BasicUtils.isNotEmpty(this.startDate) && BasicUtils.isNotEmpty(this.finishDate))) {
        await this.leadService
        .getLeadsByEmailAndCpfCnpjAndPeriod(this.email, this.cpfCnpj, this.startDate, this.finishDate)
        .then(leads => (this.leads = leads));
      } else if (BasicUtils.isEmpty(this.email) && BasicUtils.isEmpty(this.cpfCnpj) && (BasicUtils.isEmpty(this.startDate) || BasicUtils.isEmpty(this.finishDate))) {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Por favor, escolha um filtro' });
      }

      if (this.leads.length === 0) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Atenção', detail: 'Não encontramos registro para essa pesquisa!' }); 
      }

      await this.setMaskToFields();
    }

    async setMaskToFields() {
      this.leadsVo = new Array();
      if (this.leads.length > 0) {
        await this.leads.forEach(lead => {
          this.leadsVo.push(Converters.converterLeadToLeadVo(lead));
        });
      }
    }

    onCleanFilter() {
      this.email = null;
      this.cpfCnpj = null;
      this.startDate = null;
      this.finishDate = null;
      this.selectedLeads = new Array();
      this.loadInitialChange();
    }

    async onToResendLead() {
      const listId = new Array<number>();
      console.log("CHAMOU..");
      if (!BasicUtils.isEmptyList(this.selectedLeads)) {
        console.log("CHAMOU..", this.selectedLeads);
          await this.selectedLeads.forEach( lead => {
            listId.push(lead.id);
            this.editListToResendLead(lead.id);
          });
          this.selectedLeads = new Array();
          this.toResendLeadByIds(listId);
      }
    }

    toResendLeadByIds(listId){
      this.leadService.toResendLeadByIds(listId)
          .then(success => {
            this.onSearch();
            this.selectedLeads = new Array();
          }).catch(error=> {
            this.onSearch();
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Erro ao no processo de reenvio de leads!' }); 
          });

          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Sucesso', detail: 'Leads reenciados!' }); 
    }

    editListToResendLead(id){
      this.leadsVo.forEach((leadVo) => {
        if (id === leadVo.id) {
          leadVo.statusSalesForce = 'REENVIAR';
          leadVo.dtSalesForceSent = null;
        }
      });
    }
}
