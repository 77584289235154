import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Event } from '../models/Event';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [MessageService]
})
export class EventComponent implements OnInit {

  items2: MenuItem[];

  activeItem: MenuItem;

  eventSelected: Event = new Event();

  isEdit: boolean = true;

  index: number = 0;

  listEvent: Array<Event>;

  message: any;

  constructor(
    private eventService: EventService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Erro ao ataulizar o evento!' });
    this.items2 = [
      {label: 'Consultar', icon: 'fa fa-fw fa-bar-chart'},
      {label: 'Novo', icon: 'fa fa-fw fa-calendar'},
      {label: 'Editar', icon: 'fa fa-fw fa-book'},
  ];

  this.activeItem = this.items2[0];
  }

  closeItem(event, index) {
    this.items2 = this.items2.filter((item, i) => i !== index);
    event.preventDefault();
  }

  onTabChange(event) {
    this.messageService.add({severity:'info', summary:'Tab Expanded', detail: 'Index: ' + event.index});
    this.index = event.index;
    if (event.index === 1) {
      this.eventSelected = new Event();
    }
  }

  eventSelectedReceiver(event) {
    this.eventSelected = event;
    this.index = 2;
  }

  indexTabReceiver(event){
    this.eventSelected = new Event();
    this.index = event.index;
    this.eventService.findAll().then(success => this.listEvent = success);
    setTimeout(() => {
      this.messageService.add({ key: event.key, severity: event.severity, summary: event.summary, detail: event.detail });
    }, 500)
  
  }


}
