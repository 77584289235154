import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicUtils } from '../utils/basic-utils';
import { Message } from 'primeng/primeng';
import { MessageService } from 'primeng/api';
import { UserService } from '../services/user.service';
import { User } from '../models/User';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  styles: ['../assets/theme/theme-green.css', '../layout/css/layout-dark.css', './primeng.min.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  userName: string;
  password: string;
  msgs: Message[] = [];
  user: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: MessageService,
    private userService: UserService
    ) { }

  ngOnInit() {
    localStorage.setItem('userCredentials', null);
    localStorage.setItem('userLogged', null);
    localStorage.setItem('userAccess', null);
  }

  async onLogin(){
    if (this.loginValidation(this.userName, this.password)) {
      await this.userService.loginBeta(this.userName, this.password)
        .then(success => {
          if (BasicUtils.isNotEmpty(success.access_token)) {
            localStorage.setItem('userAccess', JSON.stringify(success));
            this.checkUserlogged();   
          } 
          this.service.add({ key: 'tst', severity: 'error', summary: 'Validação de Login', detail: 'Usuário ou senha inválido!' });
        }).catch(error => {
          this.service.add({ key: 'tst', severity: 'error', summary: 'Validação de Login', detail: 'Usuário ou senha inválido!' });
        });

    } 
  }

  async checkUserlogged() {
      await this.userService.getCredentilas().then(success => {
          localStorage.setItem('userCredentials', JSON.stringify(success));
          this.user = success;
          this.router.navigate(['/']); 
      }).catch(error => {
        this.router.navigate(['/login']);
      });

      const storage = localStorage.getItem('userAccess');
      if (!BasicUtils.isEmpty(storage)) {
        this.user = JSON.parse(storage);
      } else {
          this.router.navigate(['/login']);
      }
  }

  loginValidation(userName: string, password: string): boolean{
    if (!BasicUtils.isEmpty(userName) && !BasicUtils.isEmpty(password)) {
      return true;
    } else if (BasicUtils.isEmpty(userName) && !BasicUtils.isEmpty(password)) {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Validação de Login', detail: 'Por favor, entre com o usuário!' });
    } else if (!BasicUtils.isEmpty(userName) && BasicUtils.isEmpty(password)) {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Validação de Login', detail: 'Por favor, entre a senha!' });
    } else  {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Validação de Login', detail: 'Por favor, entre com o usuário e senha!' });
    }
    return false;
  }

  onLogout(){
    if (!BasicUtils.isEmpty(this.userName) && !BasicUtils.isEmpty(this.password)) {
      localStorage.setItem('userCredentials', null);
      localStorage.setItem('userLogged', null);
      localStorage.setItem('userAccess', null);
      this.router.navigate(['/login']);
     }
  }


}
