import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Event } from '../models/Event';
import { HttpClient } from '@angular/common/http';
import { BasicUtils } from '../utils/basic-utils';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private url = `${environment.gatewayUrl}/api/v1/event`;

  constructor(
    private http: HttpClient
  ) { }

  save(event: Event) {
    return this.http.post<any>(`${this.url}/save`, event)
                .toPromise()
                .then(res => <any> res);
  }

  update(event: Event) {
    return this.http.put<any>(`${this.url}/update`, event)
                .toPromise()
                .then(res => <any> res);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.url}/delete?id=${id}`)
                .toPromise()
                .then(res => <any> res);
  }

  findAll() {
    return this.http.get<any>(`${this.url}/findAll`)
                .toPromise()
                .then(res => <Event[]> res);
  }

  findByFilter(filter: any) {

    let url = `${this.url}/findByFilter?`;
    let isAndQueryParam = false;

    if (BasicUtils.isNotEmpty(filter.name)) {
        url += isAndQueryParam ? '&' : '';
        url += `name=${filter.name}`;
        isAndQueryParam = true;
    }

    if (BasicUtils.isNotEmpty(filter.eventCode)) {
      url += isAndQueryParam ? '&' : '';
      url += `eventCode=${filter.eventCode}`;
      isAndQueryParam = true;
    }

    if (BasicUtils.isNotEmpty(filter.startDate)) {
      url += isAndQueryParam ? '&' : '';
      url += `startDate=${filter.startDate}`;
      isAndQueryParam = true;
    }

    if (BasicUtils.isNotEmpty(filter.finishDate)) {
      url += isAndQueryParam ? '&' : '';
      url += `finishDate=${filter.finishDate}`;
      isAndQueryParam = true;
    }

    if (BasicUtils.isNotEmpty(filter.status)) {
      url += isAndQueryParam ? '&' : '';
      url += `status=${filter.status}`;
      isAndQueryParam = true;
    }

    return this.http.get<any>(url)
                .toPromise()
                .then(res => <Event[]> res);
  }


}
