import { DateType } from "./date-type.enum";


export class BasicUtils {

    public static isEmpty(value) {
        if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null') {
            return true;
        }
        return false;
    }

    public static isNotEmpty(value) {
        if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null') {
            return false;
        }
        return true;
    }

    public static isEmptyNumber(value) {
        if (value === null || value === undefined || value === '' || value === 0) {
            return true;
        }
        return false;
    }

    public static isNotEmptyList(value) {
        if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null' || value.length === 0) {
            return false;
        }
        return true;
    }

    public static isEmptyList(value) {
        if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null' || value.length === 0) {
            return true;
        }
        return false;
    }

    public static complementeValueLeft(valor: string, size: number, complement: string) {
        for (let i = valor.length; i < size; i++) {
            valor = `${complement}${valor}`;
        }
        return valor.toString();
    }

    public static complementeValueRigth(valor, size: number, complement: string) {
        for (let i = valor.length; i < size; i++) {
            valor = `${valor}${complement}`;
        }
        return valor.toString();
    }

    public static formatDate(date: Date, mask: string) {
        if (!this.isEmpty(date) && !this.isEmpty(mask)) {
            let day = date.getDate().toString();
            day = this.complementeValueLeft(day, 2, '0');
            let month = (date.getMonth() + 1).toString();
            month = this.complementeValueLeft(month, 2, '0');
            const year = date.getFullYear();
            const hours = this.complementeValueLeft(date.getHours().toString(), 2, '0');
            const minutes = this.complementeValueLeft(date.getMinutes().toString(), 2, '0');
            const seconds = this.complementeValueLeft(date.getSeconds().toString(), 2, '0');
            if (mask.toUpperCase() === DateType.DATE_TYPE_DD_MM_YYYY_1) {
                return `${day}/${month}/${year}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_DD_MM_YYYY_2) {
                return `${day}-${month}-${year}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_YYYY_MM_DD_1) {
                return `${year}/${month}/${day}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_YYYY_MM_DD_2) {
                return `${year}-${month}-${day}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_DD_MM_YYYY_HH_MM_SS_1) {
                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_YYYY_MM_DD_HH_MM_SS_2) {
                return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_YYYYMMDDHHMMSS) {
                return `${year}${month}${day}${hours}${minutes}${seconds}`;
            }
            if (mask.toUpperCase() === DateType.DATE_TYPE_DDMMYYYYHHMMSS) {
                return `${day}${month}${year}${hours}${minutes}${seconds}`;
            }
        }
        return null;
    }

    public static plusDay(date: Date, days: number) {
        if (!this.isEmpty(date)) {
            date.setDate(date.getDate() + days);
            return date;
        }
        return null;
    }

    public static setTime(date: Date, hours: number, minutes: number, seconds: number) {
        if (!this.isEmpty(date)) {
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setSeconds(seconds)
            return date;
        }
        return null;
    }

    public static minusDay(date: Date, days: number) {
        if (!this.isEmpty(date)) {
            date.setDate(date.getDate() - days);
            return date;
        }
        return null;
    }

    complementeValueLeft(valor: string, size: number, complement: string) {
        for (let i = valor.length; i < size; i++) {
            valor = `${complement}${valor}`;
        }
        return valor.toString();
    }

    complementeValueRigth(valor, size: number, complement: string) {
        for (let i = valor.length; i < size; i++) {
            valor = `${valor}${complement}`;
        }
        return valor.toString();
    }
}