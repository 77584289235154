import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasicUtils } from './utils/basic-utils';
import { User } from './models/User';
import { UserService } from './services/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{

    
    logged: boolean;
    public user: User;

    constructor(
        private router: Router,
        private userService: UserService
     ) {
       this.checkUserlogged();
    }

    ngOnInit(): void {
       
    }

    checkUserlogged() {

        const storage = localStorage.getItem('userAccess');
        if (!BasicUtils.isEmpty(storage)) {
           this.user = JSON.parse(storage);
        } else {
            this.router.navigate(['/login']);
        }
    }

    onLogout(){
        localStorage.setItem('userCredentials', null);
        localStorage.setItem('userLogged', null);
        localStorage.setItem('userAccess', null);
        this.router.navigate(['/login']);
    }

    // @HostListener('window:beforeunload', [ '$event' ])
    // beforeUnloadHander(event: BeforeUnloadEvent): void {
    //     this.onLogout();
    // }

}
