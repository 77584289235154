import { BasicUtils } from './../../utils/basic-utils';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { Event } from 'src/app/models/Event';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-entity-event',
  templateUrl: './entity-event.component.html',
  styleUrls: ['./entity-event.component.css'],
  providers: [MessageService]
})
export class EntityEventComponent implements OnInit {

  @Output() indexTabEmitter = new EventEmitter<any>();

  @Input() eventSelected: Event;
  eventStatus: SelectItem[];

  constructor(
    private messageService: MessageService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.loadComboEventStatus();
  }

  loadComboEventStatus() {
    this.eventStatus = new Array();
    this.eventStatus.push({label: 'Selecione o status', value: ''});
    this.eventStatus.push({label: 'ATIVO', value: 'ATIVO'});
    this.eventStatus.push({label: 'INATIVO', value: 'INATIVO'});
    this.eventStatus.push({label: 'CANCELADO', value: 'CANCELADO'});
    this.eventStatus.push({label: 'FINALIZADO', value: 'FINALIZADO'});
    this.eventStatus.push({label: 'EM ANDAMENTO', value: 'EM ANDAMENTO'});
  }

  save() {
    if (this.validatorEvent(this.eventSelected)) {
      if (BasicUtils.isEmptyNumber(this.eventSelected.id)) {
        this.eventService.save(this.eventSelected)
          .then(success => {
            this.indexTabEmitter.emit({ index: 0, key: 'tst', severity: 'success', summary: 'Sucesso', detail: 'Evento salvo com sucesso!' });
          }).catch(error => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Erro ao salvar o evento!' });
          });
      } else {
        console.log('EVENT', this.eventSelected);
        this.eventService.update(this.eventSelected)
          .then(success => {
            this.indexTabEmitter.emit({ index: 0, key: 'tst', severity: 'success', summary: 'Sucesso', detail: 'Evento atualizado com sucesso!' });
          }).catch(error => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Erro ao ataulizar o evento!' });
          });
      }
    }
  }

  cancelNewOrEditEvent() {
    if (BasicUtils.isEmptyNumber(this.eventSelected.id)) {
      this.eventSelected = new Event();
    } else {
      this.indexTabEmitter.emit({index: 0});
      this.eventSelected = new Event();
    }
  }

  validatorEvent(event: Event): Boolean {

    if (BasicUtils.isEmpty(event.name)) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Por favor entre com o nome do evento!' });
      return false;
    }

    if (BasicUtils.isEmpty(event.status)) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Validação', detail: 'Por favor entre com o status do evento!' });
      return false;
    }

    return true;
  }

}
