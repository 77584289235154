import { BasicUtils } from './../../utils/basic-utils';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Event } from 'src/app/models/Event';
import { MessageService, SelectItem } from 'primeng/api';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-list-event',
  templateUrl: './list-event.component.html',
  styleUrls: ['./list-event.component.css'],
  providers: [MessageService]
})
export class ListEventComponent implements OnInit {

  @Output() eventSelectedEmitter = new EventEmitter<Event>();
  @Input() listEvent: Array<Event>;
  @Input() message: any;

  cols: any[];
  eventFilter: Event = new Event;
  selectedEvent: Event;
  eventStatus: SelectItem[];

  constructor(
    private eventService: EventService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.loadComboEventStatus();
    this.loadAllEvents();
    this.messageService.add(this.message);
    this.cols = [
      { field: "id", header: "Id", class: "column-pattern-min", typeColumn: "text"},
      { field: "name", header: "Nome", class: "column-pattern", typeColumn: "text"},
      { field: "description", header: "Descrição", class: "column-pattern", typeColumn: "text"},
      { field: "eventCode", header: "Código", class: "column-pattern-name", typeColumn: "text"},
      
      { field: "eventPlace", header: "Local", class: "column-pattern", typeColumn: "text"},
      { field: "eventAddress", header: "Endereço", class: "column-pattern", typeColumn: "text"},
      { field: "startDate", header: "Data de inicio", class: "column-pattern", typeColumn: "date"},
      { field: "finishDate", header: "Data de termino", class: "column-pattern", typeColumn: "date"},
      { field: "status", header: "Status", class: "column-pattern", typeColumn: "text"}
    ];
  }

  loadComboEventStatus() {
    this.eventStatus = new Array();
    this.eventStatus.push({label: 'Selecione o status', value: ''});
    this.eventStatus.push({label: 'ATIVO', value: 'ATIVO'});
    this.eventStatus.push({label: 'INATIVO', value: 'INATIVO'});
    this.eventStatus.push({label: 'CANCELADO', value: 'CANCELADO'});
    this.eventStatus.push({label: 'FINALIZADO', value: 'FINALIZADO'});
    this.eventStatus.push({label: 'EM ANDAMENTO', value: 'EM ANDAMENTO'});
  }

  onCleanFilter() {
    this.eventFilter = new Event();
    this.loadAllEvents();
  }

  onSearch() {
    this.eventService.findByFilter(this.eventFilter)
      .then(success => {
        if (BasicUtils.isEmptyList(success)) {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Atenção', detail: 'Não encontramos nenhum resultado com essa pesquisa!' });
        } else {
          this.listEvent = success;
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Sucesso', detail: 'Eventos foram pesquisados com sucesso!' });
        }
      }).catch( error => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Erro ao pesquisar os eventos!' });
      });
  }

  loadAllEvents() {
    this.eventService.findAll().then(success => this.listEvent = success);
  }

  onRowSelect(event) {
    event.data.startDate = new Date(String(event.data.startDate));
    event.data.finishDate = new Date(String(event.data.finishDate));
    this.eventSelectedEmitter.emit(event.data);
  }

}
