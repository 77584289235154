import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Dealership } from '../models/Dealership';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  private url = `${environment.gatewayUrl}/api/v1/dealership`;

  constructor(
    private http: HttpClient
  ) { }

  save(dealer) {
    return this.http.post<any>(`${this.url}/save`, JSON.stringify(dealer))
                .toPromise()
                .then(res => <Dealership> res);
  }

  saveLote(dealers) {
    return this.http.post<any>(`${this.url}/save/lote`, dealers)
                .toPromise()
                .then(res => <any> res);
  }

  getAll() {
    return this.http.get<any>(`${this.url}/getAll`)
                .toPromise()
                .then(res => <any> res);
  }

  count() {
    return this.http.get<any>(`${this.url}/count`)
                .toPromise()
                .then(res => <number> res);
  }
}
