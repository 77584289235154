import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BasicUtils } from "../utils/basic-utils";
import { User } from "../models/User";
import { UserService } from "../services/user.service";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
    public menuMode = "horizontal";

    public menuActive = true;

    public topbarMenuActive = false;

    activeTopbarItem: Element;

    menuClick: boolean;

    menuButtonClick: boolean;

    topbarMenuButtonClick: boolean;

    resetMenu: boolean;

    menuHoverActive: boolean;

    logged: boolean

    user: User;

    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.user = new User();
        this.checkUserlogged();
    }

    checkUserlogged() {

        this.userService.getCredentilas().then(success => {
            localStorage.setItem('userCredentials', JSON.stringify(success));
            this.user = success;
            if (BasicUtils.isNotEmptyList(this.user.profiles)) {
                this.user.role = (this.user.profiles[0].name).replace(/ROLE_/g,'');
            } else {
                this.user.role = 'Admin'
            }
        }).catch(error => {
            this.router.navigate(['/login']);
        });

        const storage = localStorage.getItem('userAccess');
        if (!BasicUtils.isEmpty(storage)) {
           this.user = JSON.parse(storage);
        } else {
            this.router.navigate(['/login']);
        }
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick = true;
        this.menuActive = !this.menuActive;
        event.preventDefault();
    }

    onTopbarMenuButtonClick(event: Event) {
        this.topbarMenuButtonClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item: Element) {
        this.topbarMenuButtonClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onLayoutClick() {
        if (!this.menuButtonClick && !this.menuClick) {
            if (this.menuMode === "horizontal") {
                this.resetMenu = true;
            }

            if (
                this.isMobile() ||
                this.menuMode === "overlay" ||
                this.menuMode === "popup"
            ) {
                this.menuActive = false;
            }

            this.menuHoverActive = false;
        }

        if (!this.topbarMenuButtonClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        this.menuButtonClick = false;
        this.menuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onMenuClick() {
        this.menuClick = true;
        this.resetMenu = false;
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isHorizontal() {
        return this.menuMode === "horizontal";
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    onLogout(){
        localStorage.setItem('userCredentials', null);
        localStorage.setItem('userLogged', null);
        localStorage.setItem('userAccess', null);
        this.router.navigate(['/login']);
    }
}
