import { Component, OnInit, OnDestroy } from "@angular/core";
import { BasicUtils } from "../utils/basic-utils";
import { Router } from "@angular/router";
import { DealerService } from "../services/dealer.service";
import { LeadService } from "../services/lead.service";

import { Subscription } from "rxjs/internal/Subscription";
import { Observable } from "rxjs";
import { interval } from 'rxjs';



@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit, OnDestroy {
    quantDealers: number;
    quantLeads: number;
    quantLeadsToday: number;
    public example = 'Test';
    private timer;
    private updateSubscription: Subscription;


    constructor(
      private router: Router,
      private dealerService: DealerService,
      private leadService: LeadService
      ) {}

    ngOnInit() {
        this.quantDealers = 0;
        this.quantLeads = 0;
        this.quantLeadsToday = 0;
        this.onCountLeadsToday();
        this.checkUserlogged();
        this.onCountDealers();
        this.onCountLeads();
        this.updateSubscription = interval(30000).subscribe(
          (val) => { this.updateStats()
        });
    }

    ngOnDestroy() {
      this.updateSubscription.unsubscribe();
    }

    private updateStats() {
        this.onCountDealers();
        this.onCountLeads();
        this.onCountLeadsToday();
    }

    checkUserlogged() {
        const storage = localStorage.getItem("userAccess");
        if (BasicUtils.isEmpty(storage)) {
            this.router.navigate(["/login"]);
        }
    }
    
    onCountDealers() {
      this.dealerService.count().then((success) => {
        this.quantDealers = success;
      }).catch((error) => {
        console.error('Erro ao recuperar quantidade de dealres');
      });
    }

    onCountLeads(){
      this.leadService.getAllLeadsCount().then((success) => {
        this.quantLeads = success;
      }).catch((error) => {
        console.error('Erro ao recuperar quantidade de leads');
      });
    }

    onCountLeadsToday(){
      let startDate = BasicUtils.setTime(new Date(), 0, 0, 0);
      let finishDate = BasicUtils.setTime(new Date(), 0, 0, 0);
      this.leadService.getCountByPeriod(startDate, finishDate).then((success) => {
        this.quantLeadsToday = success;
      }).catch((error) => {
        console.error('Erro ao recuperar quantidade de leads')
      });
    }



}
