import { Component } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {


    constructor(
      public app: DashboardComponent
      ) {}


}
