import { Lead } from "../models/Lead";
import { LeadVo } from "../models/LeadVo";
import { BasicUtils } from "../utils/basic-utils";
import { DateType } from "../utils/date-type.enum";
import { DealerVo } from "../models/DealerVo";
import { DealerService } from "../services/dealer.service";
import { Dealership } from "../models/Dealership";
import { Model } from "../models/Model";

export class Converters{

    public static converterLeadToLeadVo(lead: Lead): LeadVo{
        const leadVo = new LeadVo();
        leadVo.id = lead.id;
        leadVo.name = lead.name;
        leadVo.nameMask = `${lead.name} ${lead.surname}`;
        leadVo.surname = lead.surname;
        leadVo.nature = lead.nature;
        leadVo.recordTypeId = lead.recordTypeId;
        leadVo.campaign = lead.campaign;
        leadVo.originId = lead.originId;
        leadVo.subOriginId = lead.subOriginId;
        leadVo.subOriginN2Id = lead.subOriginN2Id;
        leadVo.clientType = lead.clientType;
        leadVo.dateIntention = lead.dateIntention;
        leadVo.dealerCode = BasicUtils.isNotEmpty(lead.dealership) &&  BasicUtils.isNotEmpty(lead.dealership.dealerCode) ? lead.dealership.dealerCode : null;
        leadVo.offerId = lead.offerId;
        leadVo.nationalityId = lead.nationalityId;
        leadVo.modelCode = lead.model.modelCode;
        leadVo.modelYear = lead.model.modelYear;
        leadVo.modelGroup = lead.model.modelGroup;
        leadVo.productWanted = lead.model.productWanted;
        leadVo.version = lead.model.version;
        leadVo.price = lead.model.price
        leadVo.descriptionOffer = lead.descriptionOffer;
        leadVo.color = lead.model.color;
        leadVo.finishing = lead.model.finishing;
        leadVo.optional = lead.model.optional;
        leadVo.legalText = lead.legalText;
        leadVo.cep = lead.cep;
        leadVo.cpfCnpj = lead.cpfCnpj;
        leadVo.email = lead.email;
        leadVo.ddd = lead.ddd;
        leadVo.phoneNumber = lead.phoneNumber;
        leadVo.typePhone = lead.typePhone;
        leadVo.observations = lead.observations;
        leadVo.contactPreference = lead.contactPreference;
        leadVo.authorizesData = lead.authorizesData;
        leadVo.dateScheduling = lead.dateScheduling;
        leadVo.plate = lead.plate;
        leadVo.totalVehicles = lead.totalVehicles;
        leadVo.chassis = lead.chassis;
        leadVo.createDateMask =  BasicUtils.formatDate(new Date(lead.createDate), DateType.DATE_TYPE_DD_MM_YYYY_HH_MM_SS_1);
        leadVo.companyName = lead.dealership.companyName;
        leadVo.statusSalesForce = lead.statusSalesForce;
        leadVo.dtSalesForceSent = BasicUtils.isNotEmpty(lead.dtSalesForceSent) ? BasicUtils.formatDate(new Date(lead.dtSalesForceSent), DateType.DATE_TYPE_DD_MM_YYYY_HH_MM_SS_1) : null;
        leadVo.descError = lead.descError;
        leadVo.descSuccess = lead.descSuccess;
        leadVo.modelId = lead.model.id;
        leadVo.dealershipId = lead.dealership.id;
        leadVo.eventName = BasicUtils.isNotEmpty(lead.event) ? lead.event.name : null;
        return leadVo;
    }

    public static converterLeadVoToLead(leadVo: LeadVo): Lead{
        const lead = new Lead();
        lead.id = lead.id;
        lead.name = lead.name;
        lead.surname = lead.surname;
        lead.nature = lead.nature;
        lead.recordTypeId = lead.recordTypeId;
        lead.campaign = lead.campaign;
        lead.originId = lead.originId;
        lead.subOriginId = lead.subOriginId;
        lead.subOriginN2Id = lead.subOriginN2Id;
        lead.clientType = lead.clientType;
        lead.dateIntention = lead.dateIntention;
        lead.offerId = lead.offerId;
        lead.nationalityId = lead.nationalityId;
        lead.descriptionOffer = lead.descriptionOffer;
        lead.legalText = lead.legalText;
        lead.cep = lead.cep;
        lead.cpfCnpj = lead.cpfCnpj;
        lead.email = lead.email;
        lead.ddd = lead.ddd;
        lead.phoneNumber = lead.phoneNumber;
        lead.typePhone = lead.typePhone;
        lead.observations = lead.observations;
        lead.contactPreference = lead.contactPreference;
        lead.authorizesData = lead.authorizesData;
        lead.dateScheduling = lead.dateScheduling;
        lead.plate = lead.plate;
        lead.totalVehicles = lead.totalVehicles;
        lead.chassis = lead.chassis;
        lead.createDate = new Date(leadVo.createDateMask);
        lead.companyName = lead.dealership.companyName;
        lead.statusSalesForce = lead.statusSalesForce;
        lead.descError = lead.descError;
        lead.descSuccess = lead.descSuccess;
        lead.dtSalesForceSent = new Date(leadVo.dtSalesForceSent);
        
        lead.model = new Model();
        lead.model.id = leadVo.modelId;
        lead.model.color = leadVo.color;
        lead.model.finishing = leadVo.finishing;
        lead.model.optional = leadVo.optional;
        lead.model.modelCode = leadVo.modelCode;
        lead.model.modelYear = leadVo.modelYear;
        lead.model.modelGroup = leadVo.modelGroup;
        lead.model.productWanted = leadVo.productWanted;
        lead.model.version = leadVo.version;
        lead.model.price =leadVo.price

        lead.dealership = new Dealership();
        lead.dealership.id = leadVo.dealershipId;
        lead.dealership.dealerCode = leadVo.dealerCode;

        return lead;
    }

    public static converterDelaerVoToDealerShip(dealerVo: DealerVo): Dealership{
        const dealer = new Dealership();
        dealer.name =  dealerVo.Nome_Fantasia;
        dealer.companyName =  dealerVo.Razao_Social;
        dealer.dealerCode =  dealerVo.Codigo_DN;
        dealer.site =  dealerVo.Site;
        dealer.emailSale =  dealerVo.Email_de_vendas;
        dealer.emailServices =  dealerVo.Servicos;
        dealer.state =  dealerVo.Estado;
        dealer.city =  dealerVo.Cidade;
        dealer.zipcode =  dealerVo.CEP;
        dealer.address =  dealerVo.Logradouro;
        dealer.district =  dealerVo.Complemento;
        dealer.region =  dealerVo.Regiao;
        dealer.latitude =  dealerVo.Latitude;
        dealer.longitude =  dealerVo.Longitude;
        return dealer;
    }
    
}