export class Dealership{
    id: number;
    name: string;
    companyName: string;
    dealerCode: string;
    site: string;
    emailSale: string;
    emailServices: string;
    state: string;
    city: string;
    zipcode: string;
    address: string;
    district: string;
    region: number;
    latitude: string;
    longitude: string;
    createdAt: Date;
    updatedAt: Date;
}
