import { Dealership } from "./Dealership";
import { Model } from "./Model";
import { Event } from "./Event";

export class Lead{
    id: number;
    name: string;
    surname: string;
    email: string;
    cpfCnpj: string;
    typePhone: string;
    phoneNumber: string;
    cep: string;
    contactPreference: string;
    authorizesData: boolean;
    dealership: Dealership;
    companyName: string;
    createDate: Date;
    model: Model;
    ddd: number;
    nature: string;
    recordTypeId: string; 
    campaign: string; 
    originId: string; 
    subOriginId: string; 
    subOriginN2Id: string; 
    clientType: string; 
    dateIntention: string; 
    offerId: string;
    nationalityId: string;
    observations: string;
    dateScheduling: Date;
    totalVehicles: number;
    chassis: string;
    legalText: string;
    plate: string;
    descriptionOffer: string;
    statusSalesForce: string;
    dtSalesForceSent: Date;
    descError: string;
    descSuccess: string;
    event: Event;
    
}