import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import * as XLSX from "xlsx";
import { Dealership } from "../models/Dealership";
import { DealerService } from "../services/dealer.service";
import { DataScroller, Message } from "primeng/primeng";
import { BasicUtils } from "../utils/basic-utils";
import { DealerVo } from "../models/DealerVo";
import { Converters } from "../converters/Converters";
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@Component({
    selector: "app-dealer",
    templateUrl: "./dealer.component.html",
    styleUrls: ["./dealer.component.css"],
    providers: [MessageService]
})
export class DealerComponent implements OnInit {
    arrayBuffer: any;
    file: File;

    ngOnInit() {}

    uploadedFiles: any[] = [];

    dealers: Dealership[];
    dealersVo: DealerVo[];

    msgs: Message[] = [];

    loading: boolean;

    constructor(
        private messageService: MessageService,
        private dealerService: DealerService) {}

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
            this.file = file;
        }
        this.Upload();
    }

    Upload() {
        this.loading = true;
        let fileReader = new FileReader();
        fileReader.onload = e => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i)
                arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            this.dealersVo = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            this.onSaveDealerInLot(this.dealersVo)
        };
        fileReader.readAsArrayBuffer(this.file);
        this.loading = false;
    }

    onSaveDealerInLot(list: DealerVo[]){
        this.loading = true;
        const listDealers = this.normalize(list);
        if (this.validationDealers(listDealers)) {
            this.dealerService
                .saveLote(listDealers)
                .then(success => {
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: `Sucesso`,  detail: "Lista de concessionárias salva com sucesso!" });
                    this.loading = false;
                }).catch(error => {
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: `Erro`,  detail: "Erro ao adicionar lista de concessionárias!" });
                    this.loading = false;
                });
        }
    }

    validationDealers(list: Dealership[]) {
        let result = true;
        let count = 0;
        this.msgs = [];
        if (BasicUtils.isNotEmptyList(list)) {
             list.forEach(dealer => {
                count++;
                if (BasicUtils.isEmpty(dealer.name)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Nome Fantazia esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.companyName)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Razão Social esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.dealerCode)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Código DN esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.state)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Estado esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.city)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Cidade esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.zipcode)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'CEP esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.address)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Logradouro esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.district)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Complemento esta nulo!' });
                }
    
                if (BasicUtils.isEmpty(dealer.region)) {
                    result = false;
                    this.msgs.push({ severity: 'error', summary: `Erro, verifique a linha: ${count}, `, detail: 'Região esta nulo!' });
                }
    
            });
        } else {
            result = false;
            this.msgs.push({ severity: 'Error', summary: `Validação`, detail: 'Aliasta esta vazia!' });
        }
        return result;
        
    }

    showMultipleViaMessages() {
        this.msgs = [];
        this.msgs.push({ severity: 'info', summary: 'Message 1', detail: 'PrimeNG rocks' });
        this.msgs.push({ severity: 'info', summary: 'Message 2', detail: 'PrimeUI rocks' });
        this.msgs.push({ severity: 'info', summary: 'Message 3', detail: 'PrimeFaces rocks' });
    }

     normalize(list: DealerVo[]): Dealership[]{
        this.dealers = new Array();
        list.forEach((dealerVo) => {
            const dealer = Converters.converterDelaerVoToDealerShip(dealerVo);        
            this.dealers.push(dealer);    
        })

        return this.dealers;
    }
}
