

export class User{
    id: number;
    name: string;
    email: string;
    role: string;
    token: string;
    urlImg: string;
    password: string;
    profiles: any[];
}