export class Event {
	id: number;
	name: string;
	startDate: Date;
	finishDate: Date;
	description: string;
	eventCode: string;
	eventPlace: string;
	eventAddress: string;
	creationDate: string;
	updateDate: Date;
	status: string;
}