export class Model{
    id: number;
    modelCode: string;
    modelYear: number;
    modelGroup: string;
    version: string;
    price: number;
    color: string;
    optional: string;
    productWanted: string;
    finishing: string;
}