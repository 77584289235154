import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = `${environment.gatewayUrl}/api/v1/user`;

  constructor(
    private http: HttpClient
  ) { }

  login(userName: string, password: string) {
    const user = new User();
    user.email = userName;
    user.password = password;
    return this.http.post<any>(`${this.url}/login`, user)
                .toPromise()
                .then(res => <any> res);
  }

  public loginBeta(userName: string, password: string) {

    const body = new HttpParams()
    .set('grant_type', 'password')
    .set('username', userName)
    .set('password', password);

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa('devglan-client:devglan-secret'));
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const options = {
      headers: headers
    };

    return this.http.post<any>(`${environment.gatewayUrl}/oauth/token`, body, options)
      .toPromise()
      .then(res => <any> res);

  }

    getCredentilas() {
      return this.http.get<any>(`${this.url}/credentials`)
                  .toPromise()
                  .then(res => <any> res);
    }

}
