import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lead } from '../models/Lead';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  // url = 'assets/demo/data/list-lead.json';
  private url = `${environment.gatewayUrl}/api/v1/lead`;

  constructor(
    private http: HttpClient
  ) { }

  getAllLeads() {
    return this.http.get<any>(`${this.url}/getAll`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getAllLeadsCount() {
    return this.http.get<any>(`${this.url}/count`)
                .toPromise()
                .then(res => <number> res);
  }

  getCountByPeriod(startDate: Date, finishDate: Date) {
    return this.http.get<any>(`${this.url}/countByPeriod?de=${startDate}&ate=${finishDate}`)
                .toPromise()
                .then(res => <number> res);
  }

  

  getLeadsByEmail(email: string) {
    return this.http.get<any>(`${this.url}/email?email=${email}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByCpfCnpj(cpfCnpj: string) {
    return this.http.get<any>(`${this.url}/cpfcnpj?cpfCnpj=${cpfCnpj}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByPeriod(startDate: Date, finishDate: Date) {
    return this.http.get<any>(`${this.url}/period?de=${startDate}&ate=${finishDate}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByEmailAndCpfCnpj(email: string, cpfCnpj: string) {
    return this.http.get<any>(`${this.url}/email/cpfcnpj?email=${email}&cpfCnpj=${cpfCnpj}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByEmailAndCpfCnpjAndPeriod(email: string, cpfCnpj: string, startDate: Date, finishDate: Date) {
    return this.http.get<any>(`${this.url}/email/cpfcnpj/period?email=${email}&cpfCnpj=${cpfCnpj}&de=${startDate}&ate=${finishDate}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByEmailAndPeriod(email: string, startDate: Date, finishDate: Date) {
    return this.http.get<any>(`${this.url}/email/period?email=${email}&de=${startDate}&ate=${finishDate}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  getLeadsByCpfCnpjAndPeriod(cpfCnpj: string, startDate: Date, finishDate: Date) {
    return this.http.get<any>(`${this.url}/cpfcnpj/period?cpfCnpj=${cpfCnpj}&de=${startDate}&ate=${finishDate}`)
                .toPromise()
                .then(res => <Lead[]> res);
  }

  toResendLeadByIds(ids: Array<number>) {
    return this.http.post<any>(`${this.url}/toresend/lote/ids`, ids)
                .toPromise()
                .then(res => <any> res);
  }

  
}
