import { EventComponent } from './event/event.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from './home/home.component';
import { DealerComponent } from './dealer/dealer.component';
import { ListLeadComponent } from './list-lead/list-lead.component';

export const appRoutes: Routes = [
    { 
        path: "", 
        component: DashboardComponent,
        children: [
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'home', component: HomeComponent, pathMatch: 'full' },
            { path: 'dealer', component: DealerComponent, pathMatch: 'full' },
            { path: 'leads', component: ListLeadComponent, pathMatch: 'full' },
            { path: 'events', component: EventComponent, pathMatch: 'full' }
        ]
    },

    { path: "login", component: LoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
 })
 export class AppRoutesModules { }
